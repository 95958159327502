import moment from "moment-timezone";

export class Invoice {
  constructor(
    public Number: string,
    public InvoiceId: string,
    public AmountDue: number,
    public Attempted: boolean,
    public Currency: string,
    public PlanType: string,
    public Paid: boolean,
    public PeriodStart: string,
    public PeriodEnd: string,
    public ReceiptUrl: string,
    public SubscriptionId: string,
    public InvoiceUrl: string,
  ) {}

  formattedDate(dt: string): string {
    if (!dt) {
      return "";
    }
    return moment(dt).format("MM/DD/YYYY");
  }

  formattedPaid() {
    return this.Paid ? "Paid" : "Not Paid";
  }

  formattedAmountDue() {
    if (!this.AmountDue) {
      return (0.0).toFixed(2);
    }
    return (this.AmountDue / 100).toFixed(2);
  }
}
