import {Injectable} from "@angular/core";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Invoice} from "./invoice";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class InvoiceService {
  private baseUrl = environment.api + "/invoices";
  private invoices: Invoice[];

  constructor(private http: HttpClient) {}

  get(id: string): Observable<Invoice> {
    return this.list().pipe(
      map((invoices) => {
        return invoices.find((invoice) => invoice.InvoiceId === id);
      }),
    );
    /*return this.http.get<Invoice>(this.baseUrl + '/' + id).pipe(
      map((invoice) => this.mapInvoice(invoice))
    )*/
  }

  list(): Observable<Invoice[]> {
    if (this.invoices) {
      return of(this.invoices);
    }
    return this.http.get<Invoice[]>(this.baseUrl).pipe(
      map((invoices) => {
        return invoices.map((invoice) => InvoiceService.mapInvoice(invoice));
      }),
      tap((invoices: Invoice[]) => {
        this.invoices = invoices;
      }),
    );
  }

  private static mapInvoice(response): Invoice {
    return new Invoice(
      response.Number,
      response.InvoiceId,
      response.AmountDue,
      response.Attempted,
      response.Currency,
      response.PlanType,
      response.Paid,
      response.PeriodStart,
      response.PeriodEnd,
      response.ReceiptUrl,
      response.SubscriptionId,
      response.InvoiceUrl,
    );
  }

  clearCache() {
    this.invoices = null;
  }
}
